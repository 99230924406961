<template>
  <base-layout>
    <div class="row text-center">
      <div class="col-lg-3 col-xs-12"></div>
      <div class="col-lg-6 col-xs-12">
        <div class="widget widget-hover-effect2">
          <div class="widget-extra-full profile">
            <div class="icon">
              <i class="fa fa-user"></i>
            </div>
            <h4>ID: {{ Info.id }}</h4>
            <h4>Email: {{ Info.email }}</h4>
            <div class="btn-group">
              <a
                data-toggle="tooltip"
                title="Change Password"
                class="btn btn-success"
                @click="openChangePass()"
                >Change Password</a
              >
              
              <a
                data-toggle="tooltip"
                title="Enable Auth"
                class="btn "
                :class="!Auth.enable ? 'btn-info' : 'btn-danger'"
                @click="openEnableAuth()"
                >{{ !Auth.enable ? "Enable" : "Disable" }} Auth
              </a>
              <button
                v-if="InfoMetamask.connectAddressMetamask == false"
                class="btn btn-primary"
                @click="connectMeta = !connectMeta"
              >
                Connect Address
              </button>
              <vue-metamask
                v-if="connectMeta || statusDisConnectMeta == true"
                @onComplete="onComplete"
              />
              <button
                v-if="InfoMetamask.connectAddressMetamask == true"
                class="btn btn-danger"
                @click="disConnectMeta()"
              >
                Disconect Address
              </button>
              <!-- <a
                v-if="checkKYC.status === false"
                data-toggle="tooltip"
                title="transfer"
                class="btn btn-danger"
                >Not KYC
              </a>
              <a
                v-else
                data-toggle="tooltip"
                title="transfer"
                class="btn "
                :class="
                  checkKYC.status == 0
                    ? 'btn-warning'
                    : checkKYC.status == 1
                    ? 'btn-success'
                    : 'btn-danger'
                "
                >{{
                  checkKYC.status == 0 ? "Pending" : checkKYC.status == 1 ? "Actived" : "Disable"
                }}
                KYC</a
              > -->
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-lg-8 col-xs-12">
        <div class="widget widget-hover-effect2">
          <form class="form-horizontal" style="padding: 20px;">
            <ValidationProvider v-slot="{ errors }" tag="div">
              <div class="form-group" :class="{ 'has-error': errors[0] }">
                <div class="font-medium text-base col-span-12 text-white">
                  ID/Passport Number
                </div>
                <div class="col-xs-12">
                  <div class="input-group">
                    <span class="input-group-addon">ID</span>
                    <input
                      v-if="checkKYC.passport"
                      v-model="checkKYC.passport"
                      type="passport"
                      name="passport"
                      class="form-control input-lg"
                      placeholder="Enter passport"
                    />
                    <input
                      v-else
                      v-model="passport"
                      type="passport"
                      name="passport"
                      class="form-control input-lg"
                      placeholder="Enter passport"
                    />
                  </div>
                  <div v-show="errors[0]" class="animation-slideUp help-block">{{ errors[0] }}</div>
                </div>
              </div>
            </ValidationProvider>
            <div class="form-group mt-3">
              <div class="font-medium text-base col-span-12 text-white">
                ID/Passport Image
              </div>
              <p class="text-theme-11 mb-0 fz-13">
                <i class="fa fa-caret-right" aria-hidden="true"></i> Make sure the image is full and
                clear and the format is jpg, jpeg.
              </p>
              <p class="text-theme-11 fz-13">
                <i class="fa fa-caret-right" aria-hidden="true"></i> Please use image up to maximum
                2MB size!
              </p>
              <div v-if="!checkKYC.passport_image">
                <div v-if="!passport_image" class="upload_images">
                  <input
                    id="file1"
                    type="file"
                    class="upload_file_1"
                    @change="onFileChange($event, 0)"
                  />
                  <label for="file1" class="btn-2">upload</label>
                </div>
                <div v-else class="show_images">
                  <img :src="passport_image" />
                  <button class="button block bg-theme-1 text-white" @click="removeImage(0)">
                    Remove image
                  </button>
                </div>
              </div>
              <div v-else class="show_images">
                <img :src="checkKYC.passport_image" />
                <button
                  v-if="checkKYC.status == -1"
                  class="button block bg-theme-1 text-white"
                  @click="removeImage2(0)"
                >
                  Remove image
                </button>
              </div>
            </div>
            <div class="form-group mt-3" style="margin-bottom:0">
              <div class="font-medium text-base col-span-12 text-white">
                ID/Passport Image With Selfier
              </div>
              <p class="text-theme-11 mb-0 fz-13">
                <i class="fa fa-caret-right" aria-hidden="true"></i> Make sure the image is full and
                clear and the format is jpg, jpeg.
              </p>
              <p class="text-theme-11 mb-0 fz-13">
                <i class="fa fa-caret-right" aria-hidden="true"></i> Your face
              </p>
              <p class="text-theme-11 mb-0 fz-13">
                <i class="fa fa-caret-right" aria-hidden="true"></i> Your ID/Passport
              </p>
              <p class="text-theme-11 fz-13">
                <i class="fa fa-caret-right" aria-hidden="true"></i> Please use image up to maximum
                2MB size!
              </p>
              <div v-if="!checkKYC.passport_image">
                <div v-if="!passport_image_selfie" class="upload_images">
                  <input
                    id="file"
                    type="file"
                    class="upload_file_1"
                    @change="onFileChange($event, 1)"
                  />
                  <label for="file" class="btn-2">upload</label>
                </div>
                <div v-else class="show_images">
                  <img :src="passport_image_selfie" />
                  <button class="button block bg-theme-1 text-white" @click="removeImage(1)">
                    Remove image
                  </button>
                </div>
              </div>
              <div v-else class="show_images">
                <img :src="checkKYC.passport_image_selfie" />
                <button
                  v-if="checkKYC.status == -1"
                  class="button block 22 bg-theme-1 text-white"
                  @click="removeImage2(1)"
                >
                  Remove image
                </button>
              </div>
            </div>
            <div class="form-group"></div>
            <div class="form-group">
              <div class="col-xs-12 text-center">
                <button
                  type="button"
                  style="min-width:125px"
                  class="btn btn-sm btn-success"
                  @click="uploadImage()"
                >
                  <i class="fa fa-floppy-o"></i> Save
                </button>
              </div>
            </div>
          </form>
        </div>
      </div> -->
    </div>
    <v-modal name="changePassword" :width="480" :height="'auto'" :adaptive="true">
      <div class="title">Change Password</div>
      <ValidationObserver v-slot="{ invalid }">
        <form id="form-deposit" class="form-horizontal" style="padding: 20px; padding-bottom: 0;">
          <ValidationProvider rules="required|min:6|max:12" v-slot="{ errors }" tag="div">
            <div class="form-group" :class="{ 'has-error': errors[0] }">
              <div class="col-xs-12">
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-key"></i></span>
                  <input
                    v-model="passwordCurrent"
                    type="password"
                    name="passwordCurrent"
                    class="form-control input-lg"
                    placeholder="Current password"
                  />
                </div>
                <div v-show="errors[0]" class="animation-slideUp help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider
            rules="required|min:6|max:12"
            vid="password"
            v-slot="{ errors }"
            tag="div"
          >
            <div class="form-group" :class="{ 'has-error': errors[0] }">
              <div class="col-xs-12">
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-key"></i></span>
                  <input
                    v-model="password"
                    type="password"
                    name="password"
                    class="form-control input-lg"
                    placeholder="New password"
                  />
                </div>
                <div v-show="errors[0]" class="animation-slideUp help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider rules="required|confirmed:password" v-slot="{ errors }" tag="div">
            <div class="form-group" :class="{ 'has-error': errors[0] }">
              <div class="col-xs-12">
                <div class="input-group">
                  <span class="input-group-addon"><i class="fa fa-key"></i></span>
                  <input
                    v-model="passwordConfirm"
                    type="password"
                    name="passwordConfirm"
                    class="form-control input-lg"
                    placeholder="Confirm password"
                  />
                </div>
                <div v-show="errors[0]" class="animation-slideUp help-block">{{ errors[0] }}</div>
              </div>
            </div>
          </ValidationProvider>
          <div class="form-group"></div>
          <div class="form-group">
            <div class="col-xs-12 text-center">
              <button type="button" class="btn btn-sm btn-info" @click="handleChangePassword()">
                Change Password
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </v-modal>
    <v-modal name="enableAuth" :width="480" :height="'auto'" :adaptive="true" style="">
      <div class="title">{{ !Auth.enable ? "Enable" : "Disable" }} Auth</div>
      <form id="form-deposit" class="form-horizontal" style="padding: 20px;">
        <div v-if="!Auth.enable">
          <div class="font-medium text-base col-span-12 text-white">Your Secret Key</div>
          <input
            v-model="Auth.secret"
            type="text"
            readonly
            class="input w-full border col-8 form-control input-lg"
            required
          />
          <button
            v-clipboard:copy="Auth.secret"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            type="button"
            class="button w-40 block bg-theme-11 text-white radius-15 col-4"
          >
            Copy
          </button>
          <div class="intro-y col-span-12 text-center">
            <img :src="Auth.qr" alt class="mx-auto" style="max-width:350px" />
          </div>
        </div>

        <div class="font-medium text-base col-span-12 text-white">Authenticator Code</div>
        <input
          v-model="authCode"
          type="text"
          class="input form-control input-lg w-full border col-span-8"
          required
          placeholder="Enter Your Google Authenticator Code"
        />
        <button
          type="button"
          @click="handleConfirmAuth()"
          class="btn btn-success"
          style="margin:10px auto 0;display: block;"
        >
          {{ !Auth.enable ? "Enable" : "Disable" }}
        </button>
      </form>
    </v-modal>
    <v-modal name="metamaskConfirm" :width="480" :height="'auto'" :adaptive="true" style="">
      <div class="title">Connect Wallet</div>
      <form id="" class="form-horizontal" style="padding: 20px;">
        <div class="mb-10">
          <div class=" text-base text-dark">Address Connecting</div>
          <input
            v-model="metamask.address"
            type="text"
            readonly
            class="input w-full border  form-control"
            required
          />
        </div>
        <div class="mb-10">
          <div class=" text-base text-dark">Authentication Code</div>
          <input
            v-model="metamask.otp"
            type="text"
            class="input w-full border  form-control"
            placeholder="Enter Your Google Authenticator Code"
            required
          />
        </div>
        <button
          type="button"
            @click.prevent="onConfirmAddress()"
          class="btn btn-success"
          style="margin:10px auto 0;display: block;"
        >
          Confirm
        </button>
      </form>
    </v-modal>
    <v-modal name="metamaskDisConnect" :width="480" :height="'auto'" :adaptive="true" style="">
      <div class="title">Disconnect Wallet</div>
      <form id="" class="form-horizontal" style="padding: 20px;">
        <div class="mb-10">
          <div class=" text-base text-dark">Address Connected</div>
          <input
            v-model="metamask.address"
            type="text"
            readonly
            class="input w-full border  form-control"
            required
          />
        </div>
        <div class="mb-10">
          <div class=" text-base text-dark">Authentication Code</div>
          <input
            v-model="metamask.otp"
            type="text"
            class="input w-full border  form-control"
            placeholder="Enter Your Google Authenticator Code"
            required
          />
        </div>
        <button
          type="button"
            @click.prevent="onDisConfirmAddress()"
          class="btn btn-success"
          style="margin:10px auto 0;display: block;"
        >
          Confirm
        </button>
      </form>
    </v-modal>
  </base-layout>
</template>

<script>
import BaseLayout from "./layouts/Base";
import { mapGetters } from "vuex";
import VueMetamask from 'vue-metamask';
import { changePassword } from "@/services/member";
export default {
  components: { BaseLayout,VueMetamask },
  data: () => ({
    passwordCurrent: "",
    password: "",
    passwordConfirm: "",
    token: "",
    authCode: "",
    authStatus: false,
    reader: new FileReader(),
    passport_image: "",
    passport_image_selfie: "",
    passport: "",
    kyc: {
      passport: "",
      passport_image: "",
      passport_image_selfie: ""
      // status: false
    },
    connectMeta: false,
    statusDisConnectMeta: false,
    metamask: {
      address: "",
      otp: "",
    },
    kyc_status: -1
  }),
  computed: {
    ...mapGetters({
      Info: "dashboard/Info",
      InfoMetamask: "profile/InfoMeta",
      checkKYC: "dashboard/CheckKYC",
      Auth: "profile/Auth"
    })
  },
  mounted() {
    this.kyc_status = this.checkKYC.status;
    this.passport = this.checkKYC.passport;
    this.passport_image = this.checkKYC.passport_image;
    this.passport_image_selfie = this.checkKYC.passport_image_selfie;
  },
  created() {
    this.$store.dispatch("profile/req_getAuth");
    this.$store.dispatch("profile/req_infoMetamask");
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "dashboard/updateKYCSuccess":
          this.isLoading = false;
          this.kyc_status = this.checkKYC.status;
          this.passport = this.checkKYC.passport;
          this.passport_image = this.checkKYC.passport_image;
          this.passport_image_selfie = this.checkKYC.passport_image_selfie;
          break;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  methods: {
    onComplete(data){
      this.metamask.address = data.metaMaskAddress;
      if (data.metaMaskAddress && this.statusDisConnectMeta == false) {
        this.$modal.show("metamaskConfirm");
      }
      if (data.metaMaskAddress && this.statusDisConnectMeta == true) {
        this.$modal.show("metamaskDisConnect");
      }
      console.log(data.metaMaskAddress,this.statusDisConnectMeta);
    },
    onDisConfirmAddress: function() {
      this.$store.dispatch("profile/req_disConfirmConnectWallet", {
        address: this.metamask.address,
        otp: this.metamask.otp,
        // token: token
      });
      this.$modal.hide("metamaskDisConnect");
      this.metamask.otp = '';
      //window.location.reload();
    },

    onConfirmAddress: function() {
      this.$store.dispatch("profile/req_confirmConnectWallet", {
        address: this.metamask.address,
        otp: this.metamask.otp,
        // token: token
      });
      this.$modal.hide("metamaskConfirm");
      this.metamask.otp = '';
      //window.location.reload();
    },
    disConnectMeta(){
      this.connectMeta = false;
      this.statusDisConnectMeta = true;
      this.$modal.show("metamaskDisConnect");
    },
    openChangePass() {
      this.$modal.show("changePassword");
    },
    openEnableAuth() {
      this.$modal.show("enableAuth");
    },

    onCopy: function(e) {
      alert("You just copied: " + e.text);
    },
    onError: function() {
      alert("Failed to copy texts");
    },
    handleChangePassword: function() {
      // this.$startLoading();
      // await this.$recaptchaLoaded();
      // this.token = await this.$recaptcha("changepassword");
      this.$store.dispatch("profile/req_changePassword", {
        passwordCurrent: this.passwordCurrent,
        password: this.password,
        passwordConfirm: this.passwordConfirm
        //  token: token
      });
      this.$modal.hide("changePassword");
      this.passwordCurrent = "";
      this.password = "";
      this.passwordConfirm = "";
    },
    handleConfirmAuth: function() {
      if (this.authCode != "") {
        this.$store.dispatch("profile/req_changeAuth", {
          authCode: this.authCode,
          secret: this.Auth.secret
        });
        this.$store.dispatch("profile/req_getAuth");
        this.$modal.hide("enableAuth");
        this.isLoading = false;
      }
      this.$store.dispatch("profile/req_getAuth");
    },
    onFileChange(e, type) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.reader.onload = e => {
        if (type == 0) {
          this.passport_image = e.target.result;
          this.kyc.passport_image = files[0];
        } else {
          this.passport_image_selfie = e.target.result;
          this.kyc.passport_image_selfie = files[0];
        }
      };
      this.reader.readAsDataURL(files[0]);
    },
    uploadImage() {
      this.kyc.passport = this.passport;
      this.$store.dispatch("dashboard/req_kyc", this.kyc);
      this.isLoading = true;
    },
    removeImage(type) {
      if (type == 0) {
        this.passport_image = "";
      } else {
        this.passport_image_selfie = "";
      }
    },
    removeImage2(type) {
      if (type == 0) {
        this.checkKYC.passport_image = "";
      } else {
        this.checkKYC.passport_image_selfie = "";
      }
    }
  }
};
</script>

<style scoped>
.text-center {
  text-align: center !important;
}
.btn-group > .btn {
  float: none;
}
.justify-content-center {
  justify-content: center !important;
}
.font-medium,
.text-theme-11 {
  text-align: left;
  color: #fff;
  padding: 0 15px;
  margin-bottom: 0px;
}
.mb-10{
  margin-bottom: 10px;
}
.text-theme-11 {
  color: yellow;
}
.upload_images {
  width: calc(100% - 30px);
  height: 160px;
  display: flex;
  align-items: center;
  padding: 20px;
  justify-content: center;
  background: #daf2fdb3 !important;
  border-radius: 10px;
  margin: 0 15px;
  margin-bottom: 2rem;
  margin-top: 0rem;
}
.widget {
  background-color: rgb(255 255 255 / 0.25);
}
.text-dark{
  color: #444!important;
}
.show_images {
  width: calc(100% - 30px);
  height: 200px;
  position: relative;
  padding: 20px;
  margin: 0 15px;
  text-align: center;
  background: #daf2fdb3 !important;
}

.show_images img {
  height: 100%;
  width: auto;
  display: inline-block;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.show_images button {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 35px;
  background: gold;
  border: none;
  right: 0;
  padding: 5px 15px;
  bottom: 0;
  margin: auto;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.show_images:hover {
  background: #056996b3 !important;
}

.show_images:hover img {
  opacity: 0.5;
}

.show_images:hover button {
  opacity: 1;
}

.upload_file_1 {
  height: 0;
  overflow: hidden;
  width: 0;
}

.upload_file_1 + label {
  background: #f15d22;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: "Rubik", sans-serif;
  font-size: inherit;
  font-weight: 500;
  outline: none;
  padding: 10px 49px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
  justify-content: center;
  align-self: center;
  align-items: center;
  align-content: center;
  width: max-content;
}

.upload_file_1 + label.btn-2 {
  background-color: #f78b00;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 3px 4px 10px -4px rgb(0 0 0 / 0.7);
}
.upload_file_1 + label.btn-2::before {
  color: #fff;
  content: "\f382";
  font-family: "Font Awesome 5 Pro";
  font-size: 100%;
  height: 100%;
  right: 130%;
  line-height: 3.3;
  position: absolute;
  top: 0px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.upload_file_1 + label.btn-2:hover {
  background-color: #fbc500;
}
.upload_file_1 + label.btn-2:hover::before {
  right: 75%;
}
.icon {
  width: 80px;
  height: 80px;
  margin: auto;
  background: #c8973b;
  line-height: 80px;
  font-size: 36px;
  border-radius: 50%;
  color: #fff;
}
.mr-5 {
  margin-right: 5px !important;
}
.vm--modal {
  overflow-y: auto;
}
.profile h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}
.bg-theme-11 {
  margin: 0;
  height: 47px;
  display: block;
  line-height: 0;
  padding: 10px;
  background: #3ab32a;
  color: #fff;
}
.title {
  text-align: center;
  background: #1e90ff;
  padding: 5px 0;
  font-size: 15px;
  font-weight: 700;
  color: white;
}
.col-4 {
  width: 33.33333333%;
  float: left;
}
.col-8 {
  width: 66.6666667%;
  float: left;
}
</style>
